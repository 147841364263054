<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Clientes
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :loading="load"
            loading-text="Cargando... por favor espere"
            :headers="headers"
            :search="search"
            :items="users"
            no-data-text="No hay datos disponible."
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:item.id="{ item }">
              <v-icon
                color="#D50000"
                :disabled="item.lot == 0 ? true : false"
                large
                @click="seeMap(item)"
                class="mr-3"
                >room</v-icon
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar>
          <v-btn icon dark @click="dialog = false">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Mapa</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <gmap-map
            :center="center"
            :zoom="21"
            style="width:100%;  height: 650px;"
          >
            <gmap-marker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              @click="center = m.position"
            ></gmap-marker>
          </gmap-map>
        </v-card-text>

        <div style="flex: 1 1 auto;"></div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      search: "",
      load: true,
      dialog: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nameInCharge",
        },
        { text: "Correo ", value: "email" },
        { text: "Nombre de la tienda", value: "nameStore" },
        { text: "Ciudad", value: "selectCity" },
        { text: "Telefono", value: "tell" },
        { text: "Ubicacion de la tienda", value: "id", align: "end" },
      ],
      center: { lat: 19.439498901367188, lng: -70.66932678222656 },
      markers: [
        { position: { lat: 19.439498901367188, lng: -70.66932678222656 } },
      ],
    };
  },
  beforeMount() {
    this.loadUser();
  },
  computed: {
    ...mapGetters(["users"]),
  },
  methods: {
    ...mapActions(["getUsers"]),
    async loadUser() {
      await this.getUsers();
      this.load = false;
    },
    seeMap(item) {
      if (item.lat == undefined && item.long == undefined) return;
      this.center.lat = item.lot;
      this.center.lng = item.long;
      this.markers[0].position.lat = item.lot;
      this.markers[0].position.lng = item.long;
      this.dialog = true;
    },
  },
};
</script>
